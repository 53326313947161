import React from 'react'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-image-crop/dist/ReactCrop.css'

import {ToastContainer} from 'react-toastify'

import {AuthContextProvider} from 'global/firebase'
import Routes from 'global/routes'

const App = () => {
  return (
    <AuthContextProvider>
      <Routes />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        closeOnClick
        pauseOnHover
        theme="colored"
      />
    </AuthContextProvider>
  )
}

export default App
