export const socialMediaOptions = [
  {
    value: 'facebook',
    label: 'Facebook',
    icon: 'facebook',
    color: '#1877f2',
    baseUrl: 'https://www.facebook.com/'
  },
  {
    value: 'instagram',
    label: 'Instagram',
    icon: 'instagram',
    color: '#f00073',
    baseUrl: 'https://www.instagram.com/'
  },
  {
    value: 'linkedin',
    label: 'Linkedin',
    icon: 'linkedin-in',
    color: '#2867b2',
    baseUrl: 'https://www.linkedin.com/in/'
  },
  {
    value: 'twitter',
    label: 'Twitter',
    icon: 'twitter',
    color: '#1da1f2',
    baseUrl: 'https://twitter.com/'
  },
  {
    value: 'youtube',
    label: 'Youtube',
    icon: 'youtube',
    color: '#ff0000',
    baseUrl: 'https://www.youtube.com/c/'
  },
  {
    value: 'pinterest',
    label: 'Pinterest',
    icon: 'pinterest',
    color: '#e60023',
    baseUrl: 'https://pinterest.com/'
  },
  {
    value: 'tiktok',
    label: 'Tiktok',
    icon: 'tiktok',
    color: '#222222',
    svg: true,
    baseUrl: 'https://www.tiktok.com/@'
  },
  {
    value: 'spotify',
    label: 'Spotify',
    icon: 'spotify',
    color: '#1dd05d',
    baseUrl: 'https://open.spotify.com/user/'
  },
  {
    value: 'snapchat',
    label: 'Snapchat',
    icon: 'snapchat-ghost',
    color: '#f7f301',
    baseUrl: 'https://story.snapchat.com/'
  },
  {
    value: 'soundcloud',
    label: 'Soundcloud',
    icon: 'soundcloud',
    color: '#ff6911',
    baseUrl: 'https://soundcloud.com/'
  },
  {
    value: 'socialEmail',
    label: 'Email',
    icon: 'envelope',
    color: '#ffa011',
    baseUrl: 'mailto:'
  },
  {
    value: 'website',
    label: 'Website',
    icon: 'globe',
    color: '#1da1f2',
    baseUrl: ''
  },
  {
    value: 'custom',
    label: 'Custom',
    icon: 'link',
    color: '#979797',
    baseUrl: ''
  }
]
