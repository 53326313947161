import React, {memo} from 'react'

import {Box} from '@material-ui/core'

import {ReactComponent as AppleMusic} from 'global/assets/icons/social-media/appleMusic.svg'
import {ReactComponent as CustomLink} from 'global/assets/icons/social-media/custom.svg'
import {ReactComponent as Facebook} from 'global/assets/icons/social-media/facebook.svg'
import {ReactComponent as Instagram} from 'global/assets/icons/social-media/instagram.svg'
import {ReactComponent as Linkedin} from 'global/assets/icons/social-media/linkedin.svg'
import {ReactComponent as Pinterest} from 'global/assets/icons/social-media/pinterest.svg'
import {ReactComponent as Snapchat} from 'global/assets/icons/social-media/snapchat.svg'
import {ReactComponent as SocialEmail} from 'global/assets/icons/social-media/socialEmail.svg'
import {ReactComponent as Soundcloud} from 'global/assets/icons/social-media/soundcloud.svg'
import {ReactComponent as Spotify} from 'global/assets/icons/social-media/spotify.svg'
import {ReactComponent as Tiktok} from 'global/assets/icons/social-media/tiktok.svg'
import {ReactComponent as Twitter} from 'global/assets/icons/social-media/twitter.svg'
import {ReactComponent as Website} from 'global/assets/icons/social-media/website.svg'
import {ReactComponent as Youtube} from 'global/assets/icons/social-media/youtube.svg'

const SocialIcon = ({icon}) => {
  const handleIcon = () => {
    switch (icon.value) {
      case 'facebook':
        return <Facebook />
      case 'twitter':
        return <Twitter />
      case 'linkedin':
        return <Linkedin />
      case 'youtube':
        return <Youtube />
      case 'instagram':
        return <Instagram />
      case 'pinterest':
        return <Pinterest />
      case 'tiktok':
        return <Tiktok />
      case 'spotify':
        return <Spotify />
      case 'appleMusic':
        return <AppleMusic />
      case 'snapchat':
        return <Snapchat />
      case 'website':
        return <Website />
      case 'socialEmail':
        return <SocialEmail />
      case 'soundcloud':
        return <Soundcloud />
      default:
        return <CustomLink />
    }
  }

  return (
    <Box
      sx={{
        width: '28px',
        height: '28px',
        borderRadius: 1,
        background: icon.color,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      }}>
      {handleIcon()}
    </Box>
  )
}

export default memo(SocialIcon)
