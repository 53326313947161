import React, {useState} from 'react'

import {yupResolver} from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Container,
  Grid,
  Link as MaterialLink,
  CircularProgress
} from '@material-ui/core'
import {EmailOutlined, ArrowBackRounded} from '@material-ui/icons'
import {getAuth, sendPasswordResetEmail} from 'firebase/auth'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import InputText from 'components/Form/InputText'
import logo from 'global/assets/logo.png'

import {Title} from './styles'

const schema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required')
  })
  .required()

const ForgotPassword = () => {
  const auth = getAuth()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const {email} = data
    setIsSubmitting(true)

    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.info('We send you an email to reset your password.')
        setIsSubmitting(false)
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email') {
          toast.error('That email address is invalid!')
        }

        if (error.code === 'auth/too-many-requests') {
          toast.error('Too many attempts, try later')
        }

        if (error.code === 'auth/user-not-found') {
          toast.warning("We didn't find any users with this email.")
        }

        setIsSubmitting(false)
      })
  }

  return (
    <Container maxWidth="xs">
      <Grid
        sx={{flex: 1, minHeight: '100vh'}}
        container
        spacing={2}
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Grid item sx={{mb: 2, width: '100%'}}>
          <MaterialLink
            component={Link}
            to="/login"
            underline="none"
            variant="subtitle2"
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'inline-flex',
              color: '#fff'
            }}>
            <ArrowBackRounded sx={{mr: 1}} /> Back to login
          </MaterialLink>
        </Grid>
        <Grid item>
          <img src={logo} alt="" />
        </Grid>
        <Grid item>
          <Title component="span">FORGOT PASSWORD</Title>
        </Grid>
        <Grid item sx={{width: '100%'}}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item sx={{mb: 4}}>
              <InputText
                control={control}
                fullWidth
                name="email"
                label="Email"
                errorMessage={errors.email && errors.email.message}
                icon={<EmailOutlined />}
              />
            </Grid>
            <Grid item sx={{mt: 4}}>
              <Button variant="contained" type="submit" fullWidth size="large">
                {isSubmitting ? <CircularProgress size={26} /> : 'SEND'}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ForgotPassword
