import React, {useState} from 'react'

import {yupResolver} from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Container,
  Grid,
  Link as MaterialLink,
  CircularProgress
} from '@material-ui/core'
import {
  EmailOutlined,
  LockOutlined,
  ArrowForwardRounded
} from '@material-ui/icons'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import {getFirestore, doc, getDoc} from 'firebase/firestore'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import InputText from 'components/Form/InputText'
import logo from 'global/assets/logo.png'

import {useAuthState} from '../../global/firebase'
import {Title} from './styles'

const schema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be 6 or more characters.')
      .required('Password is required')
  })
  .required()

const SignIn = () => {
  const auth = getAuth()
  const db = getFirestore()
  const {updateUserData} = useAuthState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    const {email, password} = data
    setIsSubmitting(true)

    signInWithEmailAndPassword(auth, email, password)
      .then(async () => {
        const currentUser = await auth?.currentUser
        if (currentUser?.uid) {
          const userData = await getDoc(doc(db, 'users', currentUser.uid))
          updateUserData(userData)
          setIsSubmitting(false)
        }
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email') {
          toast.error('That email address is invalid!')
        }

        if (error.code === 'auth/wrong-password') {
          toast.error('Invalid email or password')
        }

        if (error.code === 'auth/too-many-requests') {
          toast.error('Too many attempts, try later')
        }

        setIsSubmitting(false)
      })
  }

  return (
    <Container maxWidth="xs">
      <Grid
        sx={{flex: 1, minHeight: '100vh'}}
        container
        spacing={2}
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Grid item>
          <img src={logo} alt="" />
        </Grid>
        <Grid item>
          <Title component="span">LOGIN</Title>
        </Grid>
        <Grid item sx={{width: '100%'}}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid item sx={{mb: 4}}>
              <InputText
                control={control}
                fullWidth
                name="email"
                label="Email"
                errorMessage={errors.email && errors.email.message}
                icon={<EmailOutlined />}
              />
            </Grid>
            <Grid item sx={{mb: 2}}>
              <InputText
                type="password"
                control={control}
                fullWidth
                name="password"
                label="Password"
                errorMessage={errors.password && errors.password.message}
                icon={<LockOutlined />}
              />
            </Grid>

            <Grid item sx={{mb: 2}}>
              <MaterialLink
                component={Link}
                to="/forgot-password"
                underline="none"
                variant="subtitle2"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  mb: 4,
                  color: '#fff'
                }}>
                Forgot password <ArrowForwardRounded sx={{ml: 1}} />
              </MaterialLink>
            </Grid>
            <Grid item sx={{mb: 2}}>
              <Button variant="contained" type="submit" fullWidth size="large">
                {isSubmitting ? (
                  <CircularProgress size={26} color="secondary" />
                ) : (
                  'LOGIN'
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                variant="outlined"
                size="large"
                to="/signup"
                fullWidth>
                CREATE ACCOUNT
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SignIn
