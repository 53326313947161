import React, {useCallback, useEffect, useRef, useState} from 'react'

import {Box, Button, CircularProgress, IconButton} from '@material-ui/core'
import {PhotoCamera} from '@material-ui/icons'
import {getFirestore, doc, updateDoc} from 'firebase/firestore'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import ReactCrop, {Crop} from 'react-image-crop'

import {useAuthState} from 'global/firebase'

import CustomModal from '../CustomModal'

interface CompletedCrop {
  x: number
  y: number
  width: number
  height: number
  unit: string
  aspect: number
}

type TonSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => void

type TGenerateDownload = (canvas: HTMLCanvasElement) => Promise<Blob>

const generateBlob: TGenerateDownload = (canvas): Promise<Blob> => {
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        resolve(blob as Blob)
      },
      'image/jpeg',
      1
    )
  })

  // canvas.toBlob(
  //   blob => {
  //     const previewUrl = window.URL.createObjectURL(blob)
  //
  //     const anchor = document.createElement('a')
  //     anchor.download = 'cropPreview.png'
  //     anchor.href = URL.createObjectURL(blob)
  //     anchor.click()
  //
  //     window.URL.revokeObjectURL(previewUrl)
  //   },
  //   'image/jpeg',
  //   1
  // )
}

const ProfileImage = () => {
  const {userData} = useAuthState()
  const imgRef = useRef<HTMLImageElement>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [showCrop, setShowCrop] = useState(false)
  const [upImg, setUpImg] = useState<FileReader['result']>()
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 300,
    height: 300,
    aspect: 16 / 9,
    x: 0,
    y: 0
  })

  const [completedCrop, setCompletedCrop] = useState<CompletedCrop>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSelectFile: TonSelectFile = evt => {
    if (evt.target.files && evt.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(evt.target.files[0])
      setShowCrop(true)
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const pixelRatio = window.devicePixelRatio

    const width = crop.width || 0
    const height = crop.height || 0
    canvas.width = width * pixelRatio
    canvas.height = height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    const cropC = crop as CompletedCrop

    ctx.drawImage(
      image,
      cropC.x * scaleX,
      cropC.y * scaleY,
      cropC.width * scaleX,
      cropC.height * scaleY,
      0,
      0,
      cropC.width,
      cropC.height
    )
  }, [crop, completedCrop])

  const getGenerateDownload = async () => {
    setIsSubmitting(true)
    const storage = getStorage()
    const db = getFirestore()

    const canvas = previewCanvasRef.current as HTMLCanvasElement
    const imgToUpload = await generateBlob(canvas)
    const storageRef = ref(storage, `${userData.slug}.jpg`)
    await uploadBytes(storageRef, imgToUpload)
    const imageUrl = await getDownloadURL(storageRef)

    const docReference = doc(db, 'users', userData.uid)
    await updateDoc(docReference, {...userData, image: imageUrl})

    setIsSubmitting(false)
    setShowCrop(false)
  }

  return (
    <>
      <Box
        sx={{
          mt: 4,
          width: '100%',
          height: {
            xs: '240px',
            sm: '340px'
          },
          borderRadius: 4,
          background: 'rgba(0,0,0,.3)',
          position: 'relative'
        }}>
        {upImg && (
          <Box
            component="div"
            sx={{
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: 4
            }}>
            <Box
              component="canvas"
              ref={previewCanvasRef}
              sx={{
                objectFit: 'cover',
                maxWidth: '100%',
                width: '100%',
                height: '100%'
              }}
            />
          </Box>
        )}

        {!upImg && userData.image && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              overflow: 'hidden',
              maxWidth: '100%',
              height: '100%',
              borderRadius: 4
            }}>
            <img
              id="profile-image"
              alt="profile"
              src={userData.image}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            background: '#494980',
            borderRadius: '100%',
            marginTop: '-20px',
            marginLeft: '-20px'
          }}
          component="label"
          htmlFor="icon-button-file">
          <input
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            id="icon-button-file"
            style={{display: 'none'}}
            onChange={onSelectFile}
          />
          <IconButton component="span">
            <PhotoCamera />
          </IconButton>
        </Box>
      </Box>
      <CustomModal open={showCrop} onClose={() => setShowCrop(false)}>
        <Box sx={{margin: 'auto'}}>
          <ReactCrop
            src={upImg as string}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c as CompletedCrop)}
            style={{
              overflow: 'hidden',
              borderRadius: 16
            }}
            imageStyle={{
              display: 'block',
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: 'fill-available'
            }}
          />
          <Box
            sx={{
              position: 'fixed',
              bottom: 32,
              left: '50%',
              transform: 'translateX(-50%)'
            }}>
            <Button
              variant="contained"
              size="large"
              onClick={getGenerateDownload}>
              {isSubmitting ? (
                <CircularProgress size={26} color="secondary" />
              ) : (
                'UPDATE IMAGE'
              )}
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  )
}

export default ProfileImage
