import React, {useState, useEffect, useContext, createContext} from 'react'

import {initializeApp} from 'firebase/app'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {getFirestore, doc, getDoc} from 'firebase/firestore'

import {IUser} from '../types'

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

const initialData = {
  uid: '',
  name: '',
  bio: '',
  email: '',
  phone: '',
  image: '',
  slug: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  youtube: '',
  pinterest: '',
  tiktok: '',
  spotify: '',
  appleMusic: '',
  snapchat: '',
  soundcloud: '',
  socialEmail: '',
  website: '',
  custom: '',
  socialMedia: []
}

const AuthContext = createContext({
  userData: initialData,
  isLoading: true,
  updateUserData: u => {}
})

export const AuthContextProvider = props => {
  const [userData, setUserData] = useState<IUser>(initialData)
  const [uid, setUid] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)
  const auth = getAuth()
  const db = getFirestore()

  const fetchInitialData = async () => {
    const docRef = doc(db, 'users', uid as string)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      setUserData(docSnap.data() as IUser)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (uid) {
      fetchInitialData()
    }
  }, [uid])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUid(user.uid)
      } else {
        setIsLoading(false)
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        userData,
        isLoading,
        updateUserData: setUserData
      }}
      {...props}
    />
  )
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return {...auth, isAuthenticated: !!auth?.userData?.uid}
}
