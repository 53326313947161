import React from 'react'

import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
  IconProps,
  IconButton
} from '@material-ui/core'
import {DeleteOutlined} from '@material-ui/icons'
import {Controller, Control} from 'react-hook-form'

interface IProps extends StandardTextFieldProps {
  control: Control<any>
  errorMessage?: string
  icon?: IconProps
  name: string
  customOnChange?: (name, value) => void
  handleRemove?: () => void
  withSlash?: boolean
}

const InputText: React.FC<IProps> = ({
  customOnChange,
  control,
  errorMessage,
  icon,
  name,
  handleRemove,
  withSlash = false,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({field}) => (
        <TextField
          {...rest}
          name={name}
          defaultValue={field.value}
          onChange={event => {
            if (customOnChange) {
              customOnChange(name, event.target.value)
            } else {
              field.onChange(event.target.value)
            }
          }}
          fullWidth
          autoComplete="off"
          error={!!errorMessage}
          helperText={errorMessage}
          variant="outlined"
          InputProps={{
            startAdornment: icon && (
              <>
                <InputAdornment position="start">{icon}</InputAdornment>
                {withSlash && '/'}
              </>
            ),
            endAdornment: handleRemove && (
              <IconButton onClick={handleRemove}>
                <DeleteOutlined />
              </IconButton>
            )
          }}
        />
      )}
    />
  )
}

export default InputText
