import {createTheme} from '@material-ui/core'

export const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif"
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#EFA40F',
      light: 'rgba(248, 148, 26, .5)'
    },
    secondary: {
      main: '#16162A',
      light: 'rgba(22, 22, 42, .5)'
    },
    background: {
      paper: '#16162A',
      default: '#16162A'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {color: 'primary'},
          style: {
            color: '#fff'
          }
        }
      ]
    }
  }
})
