import React, {memo, useEffect, useState} from 'react'

import {Box, Checkbox, FormControlLabel} from '@material-ui/core'

import SocialIcon from '../../SocialIcon'

interface IProps {
  item: any
  onChange: (v) => void
  formValue?: any
  withoutBorder?: boolean
}

const CheckboxField = ({
  item,
  onChange,
  formValue,
  withoutBorder = false
}: IProps) => {
  const [isSelected, setSelection] = useState(false)

  useEffect(() => {
    const selected = formValue.findIndex(curr => curr.value === item.value)
    setSelection(formValue && selected !== -1)
  }, [formValue, item.value])

  const handleOnChange = () => {
    const newValue = formValue || []
    const selected = newValue.findIndex(curr => curr.value === item.value)
    if (selected !== -1) {
      newValue.splice(selected, 1)
    } else {
      newValue.push(item)
    }
    onChange(newValue)

    setSelection(selected === -1)
  }

  const RenderLabel = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flex: 1
        }}>
        <SocialIcon icon={item} />
        <span style={{marginLeft: '8px', fontSize: 14, fontWeight: 500}}>
          {item.label}
        </span>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        borderTop: withoutBorder
          ? '1px solid transparent'
          : '1px solid #52526B',
        width: '100%',
        py: '4px'
      }}>
      <Box
        component="label"
        htmlFor={`checkbox-${item.value}`}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row'
        }}>
        <Checkbox
          id={`checkbox-${item.value}`}
          onChange={handleOnChange}
          checked={isSelected}
        />
        <RenderLabel />
      </Box>
    </Box>
  )
}

export default memo(CheckboxField)
