import React from 'react'

import {CircularProgress} from '@material-ui/core'
import {BrowserRouter as Router, Redirect, Route} from 'react-router-dom'

import logo from 'global/assets/logo.png'
import Dashboard from 'pages/Dashboard'
import ForgotPassword from 'pages/ForgotPassword'
import Profile from 'pages/Profile'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import VCFDownload from 'pages/VCFDownload'

import {useAuthState} from '../firebase'

const AuthenticatedRoute = ({component: C, ...props}) => {
  const {isAuthenticated} = useAuthState()
  return (
    <Route
      {...props}
      render={routeProps =>
        isAuthenticated ? <C {...routeProps} /> : <Redirect to="/login" />
      }
    />
  )
}

const UnauthenticatedRoute = ({component: C, ...props}) => {
  const {isAuthenticated} = useAuthState()
  return (
    <Route
      {...props}
      render={routeProps =>
        isAuthenticated &&
        (props.path === '/login' ||
          props.path === '/signup' ||
          props.path === '/forgot-password') ? (
          <Redirect to="/" />
        ) : (
          <C {...routeProps} />
        )
      }
    />
  )
}

const Routes = () => {
  const {isLoading} = useAuthState()
  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <img src={logo} alt="" style={{marginBottom: '24px'}} />
        <div style={{marginLeft: '-8px'}}>
          <CircularProgress size={48} color="primary" />
        </div>
      </div>
    )
  }

  return (
    <Router>
      <AuthenticatedRoute exact path="/" component={Dashboard} />
      <UnauthenticatedRoute exact path="/signup" component={SignUp} />
      <UnauthenticatedRoute exact path="/login" component={SignIn} />
      <UnauthenticatedRoute exact path="/profile/:slug" component={Profile} />
      <UnauthenticatedRoute
        exact
        path="/share/:slug"
        component={VCFDownload}
        download
      />
      <UnauthenticatedRoute
        exact
        path="/forgot-password"
        component={ForgotPassword}
      />
    </Router>
  )
}

export default Routes
