import React, {useEffect, useState} from 'react'

import {yupResolver} from '@hookform/resolvers/yup'
import {Container, Grid, Button, Box, CircularProgress} from '@material-ui/core'
import {ExitToAppOutlined} from '@material-ui/icons'
import {getAuth, signOut} from 'firebase/auth'
import {getFirestore, doc, updateDoc} from 'firebase/firestore'
import {getStorage, getDownloadURL, ref, uploadString} from 'firebase/storage'
import _ from 'lodash'
import {useForm, Controller} from 'react-hook-form'
import {Link, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import CustomModal from 'components/CustomModal'
import CheckboxField from 'components/Form/CheckboxField'
import InputText from 'components/Form/InputText'
import ProfileImage from 'components/ProfileImage'
import SocialIcon from 'components/SocialIcon'
import {ReactComponent as CirclePlusIcon} from 'global/assets/icons/circle-plus.svg'
import {ReactComponent as EditIcon} from 'global/assets/icons/edit.svg'
import logo from 'global/assets/logo.png'
import {useAuthState} from 'global/firebase'
import {IUser} from 'global/types'
import {socialMediaOptions} from 'global/utils/constants'
import {generateVCard} from 'global/utils/functions'

const schema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required')
  })
  .required()

const Dashboard = () => {
  const auth = getAuth()
  const db = getFirestore()
  const storage = getStorage()
  const {userData, updateUserData} = useAuthState()
  const history = useHistory()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSocialMediaModal, setShowSocialMediaModal] = useState(false)

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        updateUserData(null)
        history.push('/login')
      })
      .catch(error => {
        console.log(error)
        // An error happened.
      })
  }

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    unregister,
    getValues,
    reset,
    formState: {errors}
  } = useForm<IUser>({
    resolver: yupResolver(schema),
    defaultValues: userData
  })

  useEffect(() => {
    reset(userData)
  }, [userData])

  const socialMediaItems = watch('socialMedia', [])

  console.log('socialMediaItems', socialMediaItems)
  const onSubmit = async data => {
    setIsSubmitting(true)
    const updatedUser = {
      uid: userData.uid,
      ..._.omitBy(data, _.isNil)
    }

    console.log('updatedUser', updatedUser)

    const vCard = await generateVCard(updatedUser)

    const storageRef = ref(storage, `${userData.slug}.vcf`)
    await uploadString(storageRef, vCard)

    const vcardURL = await getDownloadURL(storageRef)

    const docReference = doc(db, 'users', userData.uid)

    // @ts-ignore
    delete updatedUser.image

    await updateDoc(docReference, {...updatedUser, vcardURL})

    setIsSubmitting(false)
    toast.success('Profile updated successfully')
  }

  const handleRemove = index => {
    const socialMediaValue = getValues('socialMedia')
    const fieldName = `socialMedia[${index}]`
    // @ts-ignore
    setValue(`${fieldName}.url`, '')
    // @ts-ignore
    unregister(fieldName)
    // @ts-ignore
    socialMediaValue.splice(index, 1)
    setValue('socialMedia', socialMediaValue)
  }

  const handleSocialMediaChange = (name, value) => {
    console.log('here', name, value)
    setValue(name, value)
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{pt: 4}}>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            mr: 'auto',
            ml: {
              xs: 'auto',
              sm: 0
            },
            textAlign: 'center'
          }}>
          <img src={logo} alt="" />
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={`/profile/${userData.slug}`}
            variant="outlined">
            View Profile
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleSignOut}>
            <ExitToAppOutlined sx={{mr: 1}} /> Logout
          </Button>
        </Grid>
      </Grid>

      <Grid maxWidth="100%" container alignItems="center" sx={{pt: 4}}>
        <Grid item xs={12}>
          <ProfileImage />
        </Grid>

        <Grid item xs={12}>
          <Box
            component="div"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
              pt: 8,
              pb: 4
            }}>
            <EditIcon />
            <Box component="span" sx={{fontSize: 18, pl: 1, pt: '2px'}}>
              Edit your profile
            </Box>
          </Box>

          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <InputText
                  control={control}
                  fullWidth
                  name="name"
                  label="Name"
                  errorMessage={errors.name && errors.name.message}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputText
                  control={control}
                  fullWidth
                  name="phone"
                  label="Phone"
                  errorMessage={errors.phone && errors.phone.message}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputText
                  control={control}
                  fullWidth
                  name="bio"
                  label="Bio"
                  errorMessage={errors.bio && errors.bio.message}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InputText
                  control={control}
                  fullWidth
                  name="email"
                  label="Email"
                  errorMessage={errors.email && errors.email.message}
                />
              </Grid>
            </Grid>

            <Box
              component="div"
              sx={{background: '#292946', p: 4, mt: 4, borderRadius: 2}}>
              <Button
                type="button"
                variant="outlined"
                size="large"
                sx={{p: 2}}
                onClick={() => setShowSocialMediaModal(true)}
                fullWidth>
                Add Social Media Channels
                <CirclePlusIcon style={{marginLeft: '8px'}} />
              </Button>
              {socialMediaItems && socialMediaItems.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flex: 1,
                    flexDirection: 'column'
                  }}>
                  {socialMediaItems.map((item, index) => (
                    <Box
                      key={`field-${item.value}`}
                      sx={{width: '100%', mt: 4, flex: 1}}>
                      <InputText
                        customOnChange={handleSocialMediaChange}
                        key={item.value}
                        control={control}
                        name={`socialMedia[${index}].url`}
                        errorMessage={
                          errors[item.value] && errors[item.value].message
                        }
                        label={item.label}
                        icon={<SocialIcon icon={item} />}
                        handleRemove={() => handleRemove(index)}
                        withSlash={
                          item.value !== 'custom' &&
                          item.value !== 'website' &&
                          item.value !== 'socialEmail'
                        }
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            <Grid item sx={{my: 6, maxWidth: '360px', ml: 'auto', mr: 'auto'}}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{p: 2}}
                fullWidth>
                {isSubmitting ? (
                  <CircularProgress size={26} color="secondary" />
                ) : (
                  'UPDATE CHANGES'
                )}
              </Button>
            </Grid>

            <Controller
              name="socialMedia"
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomModal
                  open={showSocialMediaModal}
                  onClose={() => setShowSocialMediaModal(false)}>
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: '360px',
                      bgcolor: 'background.default',
                      zIndex: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 'auto',
                      p: 2,
                      borderRadius: 2
                    }}>
                    {socialMediaOptions.map((item, index) => (
                      <CheckboxField
                        key={item.value}
                        item={item}
                        onChange={onChange}
                        formValue={value || []}
                        withoutBorder={index === 0}
                      />
                    ))}
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      sx={{mt: 2}}
                      onClick={() => setShowSocialMediaModal(false)}
                      fullWidth>
                      DONE
                    </Button>
                  </Box>
                </CustomModal>
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard
