import React, {useEffect, useState} from 'react'

import {Button, Grid, Container, Box, CircularProgress} from '@material-ui/core'
import {EmailOutlined, PhoneOutlined} from '@material-ui/icons'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import {Redirect, useParams} from 'react-router-dom'

import SocialIcon from 'components/SocialIcon'
import logo from 'global/assets/logo.png'
import {IUser} from 'global/types'

const VCFDownload = () => {
  const params = useParams<{slug: string}>()
  const db = getFirestore()
  const [profileData, setProfileData] = useState<IUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchVCFDownloadData = async () => {
    const docRef = collection(db, 'users')
    const q = query(docRef, where('slug', '==', params.slug))

    const snap = await getDocs(q)

    if (!snap.empty) {
      snap.forEach(doc => {
        // @ts-ignore
        setProfileData(doc.data())
      })
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchVCFDownloadData()
  }, [])

  useEffect(() => {
    if (profileData) {
      window.open(profileData.vcardURL)
    }
  }, [profileData])
  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <img src={logo} alt="" style={{marginBottom: '24px'}} />
        <div style={{marginLeft: '-8px'}}>
          <CircularProgress size={48} color="primary" />
        </div>
      </div>
    )
  }

  return !isLoading && profileData ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
      <img src={logo} alt="" style={{marginBottom: '24px'}} />
      <div style={{marginLeft: '-8px'}}>
        <CircularProgress size={48} color="primary" />
      </div>
      <p>Redirecting...</p>
      <Redirect to={`/profile/${params.slug}`} />
    </div>
  ) : (
    <Redirect to="/" />
  )
}

export default VCFDownload
