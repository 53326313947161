import React, {useEffect, useState} from 'react'

import {Button, Grid, Container, Box, CircularProgress} from '@material-ui/core'
import {EmailOutlined, PhoneOutlined} from '@material-ui/icons'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import QRCode from 'react-qrcode-svg'
import {useHistory, useParams} from 'react-router-dom'

import SocialIcon from 'components/SocialIcon'
import {ReactComponent as ContactIcon} from 'global/assets/icons/contact.svg'
import logo from 'global/assets/logo.png'
import {IUser} from 'global/types'

import {isValidHttpUrl} from '../../global/utils/functions'

const Profile = () => {
  const params = useParams<{slug: string}>()
  const history = useHistory()
  const db = getFirestore()
  const [profileData, setProfileData] = useState<IUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchProfileData = async () => {
    const docRef = collection(db, 'users')
    const q = query(docRef, where('slug', '==', params.slug))

    const snap = await getDocs(q)

    if (!snap.empty) {
      snap.forEach(doc => {
        // @ts-ignore
        setProfileData(doc.data())
      })
    } else {
      history.push('/')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchProfileData()
  }, [])

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <img src={logo} alt="" style={{marginBottom: '24px'}} />
        <div style={{marginLeft: '-8px'}}>
          <CircularProgress size={48} color="primary" />
        </div>
      </div>
    )
  }

  const handleSocialMediaURL = item => {
    const url = `${item.baseUrl}${encodeURI(item.url)}`
    let validUrl = url

    if (item.value === 'socialEmail') {
      validUrl = `${url}?subject=${profileData?.name}&body=`
    } else if (item.value === 'whatsapp') {
      validUrl = `https://wa.me/${url.replace(/\D/g, '')}`
    } else if (item.value === 'sms') {
      validUrl = `sms:${url.replace(/\D/g, '')}?body=Hi`
    } else {
      validUrl = isValidHttpUrl(url) ? url : `https://${url}`
    }

    return validUrl
  }

  return (
    !isLoading &&
    profileData && (
      <Container maxWidth="md">
        <Box
          component="div"
          sx={{
            overflow: 'hidden',
            maxWidth: '100%',
            maxHeight: '340px',
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
            mb: 4
          }}>
          <img
            alt="profile"
            src={profileData?.image || ''}
            style={{
              objectFit: 'cover',
              maxWidth: '100%',
              maxHeight: '340px',
              width: '100%',
              height: '100%'
            }}
          />
        </Box>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{mb: 2, maxWidth: '380px', mx: 'auto'}}>
          <Grid item>
            <Box
              component="strong"
              sx={{
                display: 'inline-flex',
                fontSize: 32,
                fontWeight: 500,
                textAlign: 'center'
              }}>
              {profileData.name}
            </Box>
          </Grid>
          <Grid item>
            <Box component="p" sx={{textAlign: 'center'}}>
              {profileData.bio}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          alignItems="center"
          justifyContent="center">
          <Grid item>
            <Box component="span" sx={{display: 'flex', alignItems: 'center'}}>
              <EmailOutlined sx={{mr: 1}} />
              {profileData.email}
            </Box>
          </Grid>
          <Grid item>
            <Box component="span" sx={{display: 'flex', alignItems: 'center'}}>
              <PhoneOutlined sx={{mr: 1}} />
              {profileData.phone}
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={4}
          alignItems="center"
          sx={{mt: 8, pb: 8}}>
          <Grid
            item
            sm={6}
            xs={12}
            sx={{
              order: {
                xs: 2,
                sm: 1
              }
            }}>
            <Box
              component="div"
              sx={{
                background: '#292946',
                p: 2,
                mt: 4,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column'
              }}>
              {profileData?.socialMedia?.length ? (
                profileData.socialMedia.map((item, index) => (
                  <Box
                    key={item.value}
                    component="a"
                    href={handleSocialMediaURL(item)}
                    sx={{
                      background: '#494980',
                      borderRadius: 2,
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: index === 0 ? 0 : 2,
                      color: '#fff',
                      textDecoration: 'none',
                      transition: 'all .2s linear',
                      '&:hover': {
                        background: 'rgba(73,73,128,0.8)'
                      }
                    }}>
                    <SocialIcon icon={item} />
                    <Box component="span" sx={{ml: 1}}>
                      {item.value === 'socialEmail' || item.value === 'custom'
                        ? item.url
                        : item.label}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box sx={{textAlign: 'center', opacity: 0.2}}>
                  No Social Media
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            sx={{
              order: {
                xs: 1,
                sm: 2
              },
              pb: {
                xs: 4,
                sm: 0
              }
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <Box
                sx={{
                  display: {
                    sm: 'flex',
                    xs: 'none'
                  }
                }}>
                <QRCode
                  data={`https://lion-business-card.web.app/share/${params.slug}`}
                  height="200"
                  width="200"
                  fgColor="#fff"
                  bgColor="transparent"
                />
              </Box>
              <Button
                target="_blank"
                component="a"
                href={profileData.vcardURL}
                variant="outlined"
                size="large"
                sx={{px: 6, py: 2, mt: 2}}>
                <Box sx={{mr: 1}}>Add contact</Box>
                <ContactIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  )
}

export default Profile
