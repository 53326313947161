import React from 'react'

import {Backdrop, Fade, Modal, ModalProps} from '@material-ui/core'

const CustomModal: React.FC<ModalProps> = ({children, open, onClose}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && onClose) {
            onClose(event, reason)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          display: 'flex',
          overflow: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          p: 8
        }}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>{children}</Fade>
      </Modal>
    </div>
  )
}

export default CustomModal
