import React from 'react'

import {ThemeProvider, CssBaseline} from '@material-ui/core'
import ReactDOM from 'react-dom'

import {theme} from 'global/styles/theme'

import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
