import VCard from 'global/utils/vcard-creator'

export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, '-')
}

export const shuffle = v => [...v].sort(_ => Math.random() - 0.5).join('')

export const isValidHttpUrl = (str: string): boolean => {
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str)
}

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const splitName = (
  fullName: string
): {
  firstName: string
  lastName: string
} => {
  const idx = fullName.lastIndexOf(' ')
  const firstName = idx !== -1 ? fullName.substring(0, idx) : fullName
  const lastName = idx !== -1 ? fullName.substring(idx + 1) : ''

  return {
    firstName,
    lastName
  }
}

const getBase64FromUrl = async (url: string): Promise<any> => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const generateVCard = async (userData): Promise<string> => {
  const vCardFile = new VCard()

  const userName = splitName(userData.name)

  vCardFile
    .addName(userName.lastName, userName.firstName)
    .addPhoneNumber(userData.phone)
    .addEmail(userData.email)
    .addNote(userData.bio)

  if (userData.socialMedia && userData.socialMedia.length) {
    userData.socialMedia.map((item, index) => {
      if (item.value === 'socialEmail') {
        vCardFile.addEmail(item.url)
      } else {
        const url = `${item.baseUrl}${item.url}`
        const validUrl = isValidHttpUrl(url) ? url : `https://${url}`
        vCardFile.addURLItem(validUrl, item.label, index + 1)
      }
      return false
    })
  }

  if (userData.image) {
    const base64Image = await getBase64FromUrl(userData.image)

    vCardFile.addPhoto(
      base64Image?.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
      'PNG'
    )
  }

  return vCardFile.toString()
}
